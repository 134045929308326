<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="danger"
        />
        <v-card-text class="text-center">
          <v-spacer />
          <v-row>
            <v-col cols="3">
              <v-img contain max-width="90" :src="require('@/assets/images/ers-icon-center.png')" alt="" />
            </v-col>
            <v-col>
              <h6 class="text--disabled font-weight-medium mb-10">
                휴대폰으로 전송된 인증 번호 입력
              </h6>
            </v-col>
          </v-row>

          <!-- {{ serverReady }} -->

          <div class="error" v-if="errorMessage != null">{{errorMessage}}</div>
            <v-text-field
              v-model="authNumber"
              label="인증번호 입력"
              required
            />
            <v-btn
              class="mb-4 mt-6"
              block
              color="primary"
              dark
              @click="multifactorAuth"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              인증 하기
            </v-btn>
            <v-btn
              class="mb-4 mt-6"
              block
              color="warning"
              dark
              @click="cancelAuth"
            >
              로그인 취소
            </v-btn>

        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import serverService from '@/services/server'

export default {
  name: 'MultiFactorLogin',
  metaInfo: {
    title: '멀티팩터 인증'
  },
  data() {
    return {
      serverReady: '연결되지 않음',
      show: false,
      password: 'Password',
      authNumber: '',
      checkbox1: true,
      loading: false,
      errorMessage: null,
      loginIdRules: [
        (v) => !!v || '반드시 입력해야 합니다.'
      ],
      nameRules: [
        (v) => !!v || '반드시 입력해야 합니다.',
        (v) =>
          (v && v.length <= 6) || '6자 이하로 입력해 주십시오.'
      ]
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'error'])
  },
  methods: {
    ...mapActions(['mfAuth', 'signOut']),
    multifactorAuth() {
      this.mfAuth({ authNumber: this.authNumber }).then((r) => {
        console.log('loggedInUser--->', this.$store.state.loggedInUser)
        console.log('result--->', r)
      })
    },
    cancelAuth() {
      this.signOut()
      this.$router.push('/app/sessions/sign-in')
      return null
    }
  },
  watch: {
    loggedInUser(val) {
      if (val && val.counselor && val.counselor.userId && val.counselor.userId.length > 0) {
        this.loading = true
        // this.$router.push('/')
      }
    },
    error(val) {
      if (val != null) {
        this.errorMessage = val
      }
    }
  },

  mounted() {
    serverService.ready().then(r => {
      this.serverReady = r.message
    })
  }
}
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
